import router from '@/router'
import {defineStore} from 'pinia'
import Methods from '../api/methods'
import useItemSearch from './useItemSearch'
import useLoading from './useLoading'
const loadingStore = useLoading()
const itemSearchStore = useItemSearch()

const useTop = defineStore('topPage', {
  state : () => {
    return {
      scripts             : ['tab-hash.js'],
      errors              : [],
      exhibitionSchedules : [],
      recommendedItems    : [],
      newNotices          : [],
    }
  },
  actions : {
    getExhibitionSchedules(locale = 'ja') {
      loadingStore.setLoading(true)
      Methods.apiExcute('public/get-exhibition-schedules', locale).then(data => {
        loadingStore.setLoading(false)
        this.exhibitionSchedules = data
        Methods.addExternalScripts(this.scripts)
      })
        .catch(error => {
          loadingStore.setLoading(false)
          this.errors = Methods.parseHtmlResponseError(router, error)
        })
    },
    getRecommendedItems(locale = 'ja') {
      loadingStore.setLoading(true)

      // Filter初期化
      itemSearchStore.initFilter()

      const params = {
        showedItemNos : null,
        recommending  : false,
        initLimit     : 15,
        limit         : 15,
        areas         : [],
        startPrice    : null,
        endPrice      : null,
        category      : null,
      }
      Methods.apiExcute('public/search-recommend-items', locale, params)
        .then(data => {
          this.recommendedItems = data.items
          loadingStore.setLoading(false)
        })
        .catch(error => {
          loadingStore.setLoading(false)
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    getNewNotices(locale = 'ja') {
      loadingStore.setLoading(true)
      Methods.apiExcute('public/get-new-notices', locale, {}, false).then(data => {
        loadingStore.setLoading(false)
        this.newNotices = data
      })
        .catch(error => {
          loadingStore.setLoading(false)
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    },
  },
  getters : {
  },
})

export default useTop
