<template>
  <div id="infoBid" class="info-box-bid">
    <h2>{{$t("TOP_PAGE_AUCTION_SCHEDULE_VIEW_HEADER")}}</h2>
    <div class="info-item" v-for="item in exhibitionSchedules" :key="item.exhibition_no">
      <a>
        <em v-if="item.hold_status==1" class="cate-now">{{$t("TOP_PAGE_AUCTION_STATUS_AUCTION_WILL_BE_HELD_LABEL")}}</em>
        <em v-else-if="item.hold_status==2" class="cate-hold">{{$t("TOP_PAGE_AUCTION_STATUS_AUCTION_IN_SESSION_LABEL")}}</em>
        <em v-else class="cate-fin">{{$t("TOP_PAGE_AUCTION_STATUS_AUCTION_ENDED_LABEL")}}</em>
        {{item.exhibition_name}}
        <span class="bid-day">{{item.start_date | moment($t('DATE_FORMAT'))}}～{{item.end_date | moment($t('DATE_FORMAT'))}}</span>
      </a>
    </div>
  </div>
</template>

<script>
import useLoading from '@/stores/useLoading'
import useTop from '@/stores/useTop'
import {defineComponent} from '@vue/composition-api'
export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const topStore = useTop()
    return {
      loadingStore,
      topStore
    }
  },
  props : {
    parent : {
      type    : Object,
      default : Object
    }
  },
  data() {
    return {
      scripts             : ['tab-hash.js'],
    }
  },
  computed : {
    exhibitionSchedules() {
      return this.topStore.exhibitionSchedules
    }
  },
  created() {
    this.getExhibitionSchedules()
  },
  methods : {
    getExhibitionSchedules() {
      this.topStore.getExhibitionSchedules(this.$i18n.locale)
    }
  },
  watch : {
    '$i18n.locale'() {
      this.getExhibitionSchedules()
    }
  }
})
</script>
<style scoped lang="scss">
  .info-item a {
    cursor: default !important;
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
<style scoped lang="scss" src="@/assets/scss/top.scss"></style>
