<template>
  <div id="infoNotice" class="info-box-notice">
    <h2>{{$t("TOP_PAGE_NOTICES_VIEW_HEADER")}}</h2>
    <div class="info-item">
      <a v-for="item in newNotices" :key="item.notice_no">
        <span class="notice-day">{{item.create_date}}</span>
        <div class="notice-body" v-html="item.body"></div>
        <div v-if="item.display_code == 1">
          <a v-for="file in item.file" class="notice-file" :key="file" :href="'./' + file" ><Icon icon="akar-icons:file"/>{{file.replace(/^.*[\\\/]/, '')}}</a>
        </div>
        <div v-else>
          <a v-for="file in item.file" class="notice-file"  :key="file" @click="downloadFile(file)" ><Icon icon="akar-icons:file"/>{{file.replace(/^.*[\\\/]/, '')}}</a>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import Methods from '@/api/methods'
import useLoading from '@/stores/useLoading'
import {Icon} from '@iconify/vue2'
import {defineComponent} from '@vue/composition-api'
import useTop from '../../stores/useTop'
export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const topStore = useTop()
    return {
      loadingStore,
      topStore
    }
  },
  components : {
    Icon
  },
  props : {
    parent : {
      type    : Object,
      default : Object
    }
  },
  data() {
    return {
    }
  },
  computed : {
    newNotices() {
      return this.topStore.newNotices
    }
  },
  created() {
    this.topStore.getNewNotices()
  },
  methods : {
    downloadFile(key) {
      this.loadingStore.setLoading(true)
      Methods.apiExcute('private/download-file', this.$i18n.locale, {
        key
      }).then(data => {
        this.loadingStore.setLoading(false)
        window.location.href = data
      })
        .catch(error => {
          this.loadingStore.setLoading(false)
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    }
  },
  watch : {
    '$i18n.locale'() {
      this.getNewNotices()
    }
  }
})
</script>
<style lang="scss">
  .notice-body {
    line-break: anywhere;
    img {
      max-width: 100%;
      vertical-align: bottom;
    }
    p {
      margin-bottom: initial;
      line-height: normal;
    }
  }
</style>
<style scoped lang="scss">
  .info-box-notice {
    .item-tag {
      cursor: default;
      color: black;
    }
    .info-item {
      @media only screen and (min-width: 768px) {
        max-height: 420px;
        overflow: auto;
      }
    }
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
<style scoped lang="scss" src="@/assets/scss/top.scss"></style>
