<template>
<div class="machine-recommend">
  <div class="machine-recommend-title">
    <h2>{{$t("TOP_PAGE_AUCTION_RECOMMEND_ITEMS_VIEW_HEADER")}}</h2>
  </div>
  <div v-if="recommendedItems.length == 0" class="machine-recommend-list no-item">
    <p v-if="parent.exhibitionSchedules.filter(item => item.hold_status==2).length > 0" class="no-item-msg" v-html="$t('NO_RECOMMEND_ITEM_IN_SESSION_MESSAGE', {names: parent.exhibitionSchedules.filter(item => item.hold_status==2).map(item => item.exhibition_name).join(', ')})"></p>
    <p v-else-if="parent.exhibitionSchedules.filter(item => item.hold_status ===1).length > 0" class="no-item-msg" v-html="$t('NO_RECOMMEND_ITEM_SCHEDULED_MESSAGE', {start_date: parent.exhibitionSchedules.filter(item => item.hold_status ===1)[0].start_date})"></p>
    <p v-else class="no-item-msg" v-html="$t('NO_RECOMMEND_ITEM_NO_SCHEDULE_MESSAGE')"></p>
  </div>
  <div v-else class="machine-recommend-list d-flex">
    <a
      v-for="item in recommendedItems"
      :key="item.exhibition_item_no"
      @click="$routerGo(item.auction_classification === '1' ? $define.PAGE_NAMES.AUCTION_DETAIL_PAGE_NAME : $define.PAGE_NAMES.TENDER_DETAIL_PAGE_NAME, item.exhibition_item_no, {filter : encodeURIComponent(JSON.stringify(filter))})"
      class="machine-recommend-list-box align-self-end"
    >
      <figure>
        <img v-if="item.images && item.images.length > 0 && item.images.find(file => !file.postar_file_path && file.file_path)"
          :src="(item.images.find(file => !file.postar_file_path && file.file_path).file_path) | getFullImagePath"  alt="">
        <img v-else src="@/assets/img/no_photo.jpg" alt="">
      </figure>
      <div class="machine-recommend-list-box-text">
        <dl>
          <dt>{{bikeTypeTxt(item.free_field.bike_type || '')}}</dt>
          <dd>{{$t("TOP_PAGE_CUBIC_CAPACITY")}}：{{item.free_field.cubic_capacity_other | getCubicText($t('ITEM_FREE_FIELD_FUMEI'))}}</dd>
          <dd>{{$t("TOP_PAGE_MILEAGE")}}：{{mileageComma(item.free_field.mileage_other) || $t('ITEM_FREE_FIELD_FUMEI')}}</dd>
          <dd>{{$t("TOP_PAGE_INSPECTION_DATE")}}：{{item.inspec_expire_other_txt || ''}}</dd>
        </dl>
        <p class="price">
          <span class="yen">{{$t("TOP_PAGE_PRICE_CURRENCY_MARK")}}</span>
          <span v-if="item.bid_status" class="member-only">{{item.price_display_flag != 0 ? item.bid_status.current_price.numberString() : 'ASK'}}</span>
          <span v-else class="member-only">{{'ASK'}}</span>
        </p>
      </div>
    </a>
  </div>
  <div class="btnWrap">
    <a
      class="indexBtn default"
      @click="$routerGo(
        $define.PAGE_NAMES.AUCTION_LIST_PAGE_NAME,
        null,
        {filter : encodeURIComponent(JSON.stringify({initCrane: false}))}
      )">
      {{ $t('TOP_PAGE_SHOW_LIST_BTN') }}
    </a>
  </div>
</div>
</template>
<script>
import CommonFilters from '@/common/commonFilters'
import useTop from '@/stores/useTop'
import {defineComponent} from '@vue/composition-api'
export default defineComponent({
  setup() {
    const topStore =  useTop()
    return {
      topStore
    }
  },
  props : {
    parent : {
      type    : Object,
      default : Object
    },
    search : {
      type    : Function,
      default : Object
    }
  },
  mixins : [CommonFilters],
  data() {
    return {
      extraFilter : {
        initLimit    : 12,
        recommending : true
      }
    }
  },
  computed : {
    recommendedItems() {
      return this.topStore.recommendedItems
    }
  },
  created() {
    this.topStore.getRecommendedItems()
  },
  methods : {
    mileageComma(val) {
      if (val) return this.$options.filters.number2string(val, null, 'km')
      else return null
    },
    bikeTypeTxt(val) {
      if (val && val.length > 10) {
        return `${val.substring(0, 10)}...`
      }
      return val
    }
  }
})
</script>
<style scoped lang="scss">
  .machine-recommend-list .no-item {
      text-align: center;
  }
  .machine-recommend-list .no-item .no-item-msg {
    width: 100%;
    height: calc(100% - 260px);
  }
  .machine-recommend-list-box {
    display: block;
    height: 100%;
    position: relative;

    figure {
      position: relative;
      height: 145px;
    }
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
<style scoped lang="scss" src="@/assets/scss/top.scss"></style>
